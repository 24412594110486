<!--CommentInput:回帖/评论 输入框 2024/07/27 15:34:27 huangmx-->

<template>
  <div class="comment-input">
    <el-input type="textarea" rows="5" :placeholder="$t('inputContent')" v-model="replyMsg">
    </el-input>
    <el-button type="primary" class="send-btn" size="small" @click="publish">{{ $t('send') }}</el-button>
  </div>
</template>
<script>
import { commentApi, trackApi } from '@/utils/api'
export default {
  name: 'CommentInput',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      replyMsg: ''
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    publish: function () {
      this.$message.success(this.$t('commentSuccess'))
      // 回复评论 埋点
      trackApi.articleTrack('COMMENT', null, null, this.item.id)
      commentApi.replyComment(this.item.id, this.item.groupKey, this.replyMsg, []).then(resp => {
        if (resp.httpStatus === 200) {
          console.log('评论成功', resp)
        }
      })
    }
  }
}
</script>

<style scoped>
.comment-input {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  margin: auto;
  position: relative;
  width: 739px;
  height: 128px;
}

.comment-input .send-btn {
  position: absolute;
  right: 12px;
  bottom: 20px;
}
</style>
