<!--NotifyListItem:回帖/评论 2024/07/27 15:18:56 huangmx-->

<template>
  <div class="notify-list-item">
    <head-common :item="item.otherPeopleComment.sender" :show-footer="false">
      <p class="post-msg">
        <span>{{ $t('commentYou') }}</span>
        <span>{{ new Date(item.otherPeopleComment.gmtCreate).getTime() | formatDate }}</span>
      </p>
    </head-common>
    <!--别人的评论内容-->
    <comment :item="item.otherPeopleComment"></comment>
    <comment-input v-if="isTa === false" :item="item.otherPeopleComment"></comment-input>
    <div>
      <!--自己的评论内容-->
      <comment class="my-comment" :item="item.myComment"></comment>
      <!--评论的咨询/帖子-->
      <feed-list-common class="article-item" :item="item.article" :show-header="false"
        :show-footer="false"></feed-list-common>
    </div>
  </div>
</template>
<script>
import HeadCommon from '@/views/components/HeadCommon'
import Comment from '@/views/components/Comment'
import CommentInput from '@/views/components/CommentInput'
import FeedListCommon from '@/views/components/FeedListCommon'
export default {
  name: 'NotifyListItem',
  components: { HeadCommon, Comment, FeedListCommon, CommentInput },
  props: {
    item: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    isTa: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tip: 'Hi, 新的组件'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
  }
}
</script>

<style scoped>
.notify-list-item {
  background-color: white;
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 20px;
}

.my-comment {
  border-bottom: 1px dashed #eee;
}

.article-item {
  padding: 0;
}

.post-msg {
  margin-left: 20px;
  font-weight: bold;
}

.post-msg span {
  margin-right: 20px;
}
</style>
