<!--index:回帖/评论 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="notify-index" v-infinite-scroll="getData" :infinite-scroll-disabled="!loadMore"
    :infinite-scroll-immediate=false :infinite-scroll-distance=30>
    <div v-if="isTa">
      <friend-news-item v-for="(item, index) in list" :key="'fire-ta-' + index" :item="item"
        :is-ta="isTa"></friend-news-item>
    </div>
    <div v-else>
      <notify-list-item v-for="(item, index) in list" :key="'post' + index" :item="item"
        :is-ta="isTa"></notify-list-item>
    </div>
  </div>
</template>
<script>
import NotifyListItem from './components/NotifyListItem'
import FriendNewsItem from '@/views/user/components/FriendNewsItem.vue'

import { commentApi, userApi, articleApi, interactionApi } from '@/utils/api'
export default {
  name: 'index',
  components: { NotifyListItem, FriendNewsItem },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      currentPage: 1,
      list: [],
      isloading: false,
      loadMore: true,
      isTa: this.$route.query.id !== undefined && this.$route.query.id !== ''
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData: function () {
      if (this.isTa) {
        this.getUserDynamicList()
      } else {
        this.getMyData()
      }
    },
    getMyData: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      commentApi.getOthersCommentList(this.currentPage).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200 && resp.data) {
          console.log('getOthersCommentList', resp.data)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.list = this.list.concat(resp.data)
        }
      }).catch(err => {
        console.log('getOthersCommentList error', err)
        this.isloading = false
      })
    },
    getUserDynamicList: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      userApi.getUserDynamicList({
        userIds: this.$route.query.id || this.$store.state.user.id,
        userDynamicType: 'PUBLISH_ARTICLE_COMMENT',
        currentPage: this.currentPage,
        pageSize: 10
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200 && resp.data) {
          console.log('getUserDynamicList', resp)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          if (resp.data.length === 0) {
            return
          }
          this.list = this.list.concat(resp.data)
          this.currentPage++

          const articles = resp.data.filter(item => item.article !== null)
          if (articles && articles.length > 0) {
            const idSet = new Set(articles.map(item => item.article.id))
            const articleIds = Array.from(idSet).join(',')
            this.getArticleInteractions(articleIds)
          }

          const commentIdSet = new Set(resp.data.map(item => item.articleComment.id))
          const commentIds = Array.from(commentIdSet).join(',')
          this.getCommentInteractions(commentIds)
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articleIds) {
      if (!articleIds || articleIds.length === 0) {
        return
      }
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          // 为 articles 追加 互动数据
          const interactionMap = new Map(resp.data.map(interaction => [interaction.articleId, interaction]))
          this.list = this.list.map(item => {
            const interactionData = interactionMap.get(item.article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              item.article = {
                ...item.article,
                like: interactionData.like,
                likeInteractionRecordId: interactionData.likeInteractionRecordId,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            }
            return item
          })
          console.log('notify 互动', this.list)
        }
      })
    },
    getCommentInteractions: function (commentIds) {
      if (!commentIds || commentIds.length === 0) {
        return
      }
      const params = {
        chatIds: commentIds
      }
      interactionApi.getChatInteraction(params).then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          const interactionMap = new Map(resp.data.map(interaction => [interaction.chatId, interaction]))
          this.list = this.list.map(item => {
            if (!item.articleComment) {
              return item
            }
            const interactionData = interactionMap.get(item.articleComment.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              item.articleComment = {
                ...item.articleComment,
                interaction: interactionData
              }
            }
            return item
          })
          console.log('获取评论互动成功', this.list)
        }
      })
    }
  }
}
</script>

<style scoped>
.notify-index {
  min-height: 700px;
  overflow-y: auto;
}
</style>
